<!-- use tags -->
import "#styles/label.scss";
export interface Input extends Marko.Input<"input"> {
  label: string;
}

<const/{ autocomplete, label, name, required }=input/>
<id/passwordId/>
<let/type=("password" as "password" | "text")/>
<const/isPassword=type === "password"/>

<div>
  <label class="label" for=passwordId>
    ${label}
  </label>
  <div class="input-password">
    <input
      autocomplete=autocomplete
      class="input-password__input"
      id=passwordId
      name=name
      required=required
      type=type
    >
    <button
      aria-label=(isPassword ? "Show password" : "Hide password")
      class="input-password__toggle"
      data-testid="password-toggle"
      onClick() {
        type = isPassword ? "text" : "password";
      }
      type="button"
    >
      <sb-icon aria-hidden icon=(isPassword ? "eye-slash" : "eye")/>
    </button>
  </div>
</div>

<style.scss>
  .input-password {
    position: relative;

    &__input {
      padding-right: $spacing-32;
    }

    &__toggle {
      align-items: center;
      background: none;
      border: none;
      bottom: 0;
      cursor: pointer;
      display: flex;
      padding: 0 $spacing-12;
      position: absolute;
      right: 0;
      top: 0;
      z-index: $z-low;
    }
  }
</style>
